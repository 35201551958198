export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Shipments',
    route: 'shipments',
    icon: 'PackageIcon',

  },

  {
    title: 'Configurations',
    route: 'configurations',
    icon: 'SettingsIcon',

  },

  {
    title: 'Selling Partners',
    route: 'amz-selling-partners',
    icon: 'ShoppingCartIcon',

  },
  {
    title: 'SearchTerms Report',
    route: 'search-terms',
    icon: 'EyeIcon',
  },
  {
    title: 'Amzon Ads',
    route: 'amz-ads-accounts',
    icon: 'TargetIcon',

  },
  {
    title: 'Noitifications',
    route: 'notifications',
    icon: 'MessageSquareIcon',

  },
  {
    title: 'Document to BQ',
    icon: 'SaveIcon',
    children: [
      {
        title: 'Documents',
        route: 'documents',
        icon: 'FileIcon',

      },

      {
        title: 'Templates',
        route: 'documents-templates',
        icon: 'SettingsIcon',

      },
    ],

  },

  {
    title: 'SkuVault',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Items quantities',
        route: 'skauvault-quantities',
        icon: 'ArchiveIcon',

      },
    ],

  },

  {
    title: 'Tools',
    icon: 'ToolIcon',
    children: [
      {
        title: 'BigQuery row',
        route: 'tools-bigquery',
        icon: 'DatabaseIcon',

      },
    ],

  },
]
