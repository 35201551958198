<template>
  <div>
    <span class="float-right">Version : <b>{{ version }}</b> | Time zone : <b>PST</b>  / now:  <b>{{ new Date() | formatDateTime() }} </b></span>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { version } from '@/../package.json'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      version,
    }
  },
}
</script>
